import React from 'react';
import type { FC } from 'react';

import { useNthUserHomeOnboardingTakeoverV2Eligibility } from '../hooks/useNthUserHomeOnboardingTakeoverV2Eligibility';

import { AdminHomeOnboardingTakeoverV2HomeNudge } from './AdminHomeOnboardingTakeoverV2HomeNudge';
import { NthUserHomeOnboardingTakeoverV2HomeNudge } from './NthUserHomeOnboardingTakeoverV2HomeNudge';

type HomeOnboardingTakeoverV2HomeNudgeProps = {
	position?: 'bottom-start' | 'right-start';
	children?: React.ReactNode;
};

export const HomeOnboardingTakeoverV2HomeNudge: FC<HomeOnboardingTakeoverV2HomeNudgeProps> = ({
	children,
	position = 'bottom-start',
}) => {
	const { isEligible: v2NthUserEligible, loading: v2NthUserLoading } =
		useNthUserHomeOnboardingTakeoverV2Eligibility();

	if (v2NthUserLoading) {
		return children;
	}

	if (v2NthUserEligible) {
		return (
			<NthUserHomeOnboardingTakeoverV2HomeNudge position="bottom-start">
				{children}
			</NthUserHomeOnboardingTakeoverV2HomeNudge>
		);
	}

	return (
		<AdminHomeOnboardingTakeoverV2HomeNudge position={position}>
			{children}
		</AdminHomeOnboardingTakeoverV2HomeNudge>
	);
};
